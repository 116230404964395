/* 印尼语 */
export default {
  emailRegister: 'Daftar Email',
  registerNotice:
    'Akun yang belum terdaftar akan secara otomatis didaftarkan dan masuk',
  enterEmail: 'Silakan masukkan alamat email Anda',
  enterCode: 'Silakan masukkan kode Anda',
  getCode: 'Dapatkan kode',
  signIn: 'Masuk sekarang',
  signPropt: 'Dengan masuk, Anda setuju dengan',
  policy: 'Kebijakan Privasi',
  agreement: 'Perjanjian Pengguna',
  emailRuleError: 'Format email tidak benar',

  starcard: `Keuntungan Keanggotaan Kartu Bintang`,
  more: `Lebih banyak`,
  describe: `Kartu Bintang adalah bukti hak istimewa sistem promosi Jubaopen. Hanya dengan memiliki Kartu Bintang Anda dapat menikmati serangkaian manfaat dan hak istimewa dari sistem promosi Jubaopen. Kartu Bintang terbagi menjadi Kartu Bintang Uji Coba dan Kartu Bintang Eksklusif. Pengguna Kartu Bintang dapat memperoleh hadiah tunai.`,
  starReward: `Hadiah Promosi Kartu Bintang`,
  unlock: `Buka Kunci`,
  text1: `· Hadiah Undangan: Undang pengguna secara langsung atau tidak langsung untuk membeli keanggotaan Kartu Bintang dan dapatkan hadiah 10% dan 5%.`,
  text2: `· Hadiah Promosi Investasi Hak Cipta: Undang pengguna secara langsung atau tidak langsung untuk berinvestasi dalam promosi hak cipta dan dapatkan 10% dan 5% dari hasil harian.`,
  text3: `· Hadiah Menonton: Undang pengguna secara langsung untuk membuka satu episode dan dapatkan hadiah 40%, atau langganan anggota drama pendek dapatkan hadiah 20%.`,
  starClu: `Hadiah Grup Bintang`,
  text4: `Silakan hubungi layanan pelanggan resmi atau pengundang untuk aturan hadiah yang lebih rinci`,
  copyright: `Crowdfunding Hak Cipta`,
  td1: `Tingkat Pengembalian Tahunan`,
  td2: `Jumlah Investasi Awal`,
  td3: `Selesai/Total Dana`,
  warning: `*Hasil akan berfluktuasi setiap hari sesuai dengan efek promosi aktual.`,
  schedule: `Kemajuan Crowdfunding`,
  buynow: `Beli Sekarang`,
  download: `Unduh`,
  go: `Pergi`,
  android: `Android`,
  apple: `App Store`,
  Characteristic: `Karakteristik`,
  Participation: `Jumlah Partisipasi`,
  method: `Metode Pembayaran`,
  amount: `Jumlah Pembayaran`,
  jump: `Lompat ke Pembayaran`,
  name1: `Drama Pendek`,
  name2: `Berbagai Jenis`,
  name3: `Anggota Kartu Bintang`,
  name4: `Pendapatan Saluran`,
  describe1: `Jaminan Kualitas`,
  describe2: `Memikirkan Anda`,
  describe3: `Subsidi Miliaran`,
  describe4: `Pekerjaan Sampingan Utama`,
  warning1: `Konten tidak boleh kosong`,
  success1: `Berhasil Terhubung`,
  ends: `Hitung Mundur Berakhir`,
  warning2: `Anda belum login, silakan login terlebih dahulu`,
};
